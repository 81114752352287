<template >
  <div class="DWP">
    <Explainer
      m="INSTANT WITHDRAWALS WITH THE BEST PRICE"
      title="YieldYak DWP"
      p="Dynamic Withdrawal Pools are stableswap pools with a dynamic peg, providing instant exit liquidity for the Geode Universe."
      :back="true"
    />
    <div class="PoolView">
      <div class="split shared-bg couple-button">
        <button
          :class="{
            'left-button': true,
            'switch-on-button': isAdd,
            'switch-off-button': !isAdd,
          }"
          @click="Switch(true)"
        >
          Add Liquidity
        </button>
        <button
          :class="{
            'right-button': true,
            'switch-on-button': !isAdd,
            'switch-off-button': isAdd,
          }"
          @click="Switch(false)"
        >
          Remove Liquidity
        </button>
      </div>
      <div class="poolAction shared-bg">
        <button @click="addMetamask" class="cornerButton">
          Add WP Token to Metamask
        </button>
        <div class="percentage" v-if="isAdd && userAddress">
          > You have
          {{ displayBalances[0] }} Avax and {{ displayBalances[1] }} yyAvax
        </div>
        <div class="percentage" v-else-if="!isAdd && userAddress">
          > You have
          {{ displayBalances[2] }} WP Tokens
        </div>
        <div class="gap-21" v-else></div>
        <div :class="{ 'split gap input-wrapper': !mobile }">
          <InputSpace
            name="Avax"
            class="input-avax"
            :max="isAdd ? AvaxBal : maxSingularWithdrawal[0]"
            :data="false"
            @maxed="maxedOne"
            @update="setValue"
            ref="InputSpace1"
            @click.native="clearPercentage"
          ></InputSpace
          ><br v-if="mobile" />
          <InputSpace
            name="yyAVAX"
            class="input-gavax"
            :max="isAdd ? gAvaxBal : maxSingularWithdrawal[1]"
            :data="false"
            @update="setValue"
            @maxed="maxedOne"
            ref="InputSpace2"
            @click.native="clearPercentage"
          ></InputSpace>
        </div>
        <div :class="{ split: !mobile }">
          <div>
            <AttributeSelection
              :mobile="mobile"
              name="Max Slippage"
              choice1="0.1"
              choice2="0.5"
              :unit="'%'"
              :max="99"
              @change="setSlippage"
            ></AttributeSelection>
            <AttributeSelection
              :mobile="mobile"
              name="Tx Deadline"
              choice1="10"
              choice2="30"
              :unit="'min'"
              @change="setDeadline"
            ></AttributeSelection>
          </div>
          <br v-if="mobile" /><br v-if="mobile" />
          <div class="text-right button-zone">
            <div class="percentage" v-if="!isAdd">
              > Withdraw both equally, by %: {{ percentage }}
              <input
                type="range"
                min="0"
                max="100"
                step="5"
                v-model="percentage"
                @change="setByPercentage"
              />
            </div>
            <div class="percentage" v-else>
              > You will get:
              {{
                forecastedWPamount
                  ? forecastedWPamount.dividedBy(1e18).toFixed(2).toString()
                  : 0
              }}
              WP Tokens
            </div>
            <div
              class="content-right align-vertically approval gap-53"
              v-if="isAdd"
            >
              <p class="display-inline">ERC1155 Approval</p>
              <CheckWrapper :value="approval"></CheckWrapper>
            </div>
            <div class="content-right align-vertically approval gap-53" v-else>
              <p class="display-inline">WP Token Approval</p>
              <CheckWrapper
                :value="
                  this.WPApproval > 0
                    ? this.WPApproval.gte(this.ownedWP)
                    : false
                "
              ></CheckWrapper>
            </div>
            <div class="button-wrap">
              <Button
                :text="ButtonText ? ButtonText : 'Connect'"
                :disabled="isDisabled"
                @click.native="Execute()"
                :color="isDisabled ? 'transparent' : '18aaa180'"
                :fill="true"
                :whiteText="true"
                :loading="loading"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        :class="{
          'split-six shared-bg poolDetails': !mobile,
          'split-six scroll shared-bg poolDetails': mobile,
        }"
      >
        <div class="align-vertically stat-wrapper" style="grid-area: area1">
          <div>
            <p class="stat-name">Reserves</p>
            <ImageVue
              :src="'dynamic/loading.svg'"
              class="loading"
              size="30px"
              v-if="loadingData"
            />
            <div class="stat-data" v-else>
              {{ poolData.reserve1 }}
              <span class="stat-name">Avax</span>
              <p class="stat-name">and</p>
              {{ poolData.reserve2 }}
              <span class="stat-name">yyAvax</span>
            </div>
          </div>
        </div>
        <div
          class="align-vertically non-border-in2column stat-wrapper"
          style="grid-area: area2"
        >
          <div>
            <p class="stat-name">Status</p>
            <ImageVue
              :src="'dynamic/loading.svg'"
              class="loading"
              size="30px"
              v-if="loadingData"
            />
            <div class="circle green-bg" v-else></div>
          </div>
        </div>
        <div
          class="align-vertically stat-wrapper top-border-inmobile"
          style="grid-area: area3"
        >
          <div>
            <p class="stat-name">Virtual Price</p>
            <ImageVue
              :src="'dynamic/loading.svg'"
              class="loading"
              size="30px"
              v-if="loadingData"
            />
            <p class="stat-data" v-else>{{ poolData.virtualPrice }}</p>
          </div>
        </div>
        <div
          class="align-vertically stat-wrapper top-border-inmobile"
          style="grid-area: area4"
        >
          <div>
            <p class="stat-name">A Parameter</p>
            <ImageVue
              :src="'dynamic/loading.svg'"
              class="loading"
              size="30px"
              v-if="loadingData"
            />
            <p class="stat-data" v-else>{{ poolData.aParameter }}</p>
          </div>
        </div>
        <div
          class="
            non-border-in2column
            align-vertically
            stat-wrapper
            top-border-inmobile
          "
          style="grid-area: area5"
        >
          <div>
            <p class="stat-name">Fee</p>
            <ImageVue
              :src="'dynamic/loading.svg'"
              class="loading"
              size="30px"
              v-if="loadingData"
            />
            <p class="stat-data" v-else>{{ poolData.fee }} %</p>
          </div>
        </div>
        <div
          class="
            non-border
            align-vertically
            stat-wrapper
            non-border-in2column
            top-border-inmobile
          "
          style="grid-area: area6"
        >
          <div>
            <p class="stat-name">Admin Fee</p>
            <ImageVue
              :src="'dynamic/loading.svg'"
              class="loading"
              size="30px"
              v-if="loadingData"
            />
            <p class="stat-data" v-if="!loadingData">
              {{ poolData.adminFee }}%
            </p>
            <p class="stat-name" v-if="!loadingData">of {{ poolData.fee }}%</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImageVue from "@/components/utils/ImageVue";
import InputSpace from "@/components/utils/InputSpace";
import CheckWrapper from "@/components/utils/CheckWrapper";
import AttributeSelection from "@/components/utils/AttributeSelection";
import Explainer from "@/components/utils/Explainer";
import Button from "@/components/utils/ButtonVue";
import { mapGetters, mapActions } from "vuex";
import { notifyHandler } from "@/utils/common";
import BN from "bignumber.js";
import axios from "axios";
import { getWithdrawalPool, getWPToken } from "@/contracts";
import { timeout } from "@/utils/common";
import { constants } from "ethers";
const maxUint = constants.MaxUint256;

export default {
  props: ["mobile"],
  components: {
    InputSpace,
    CheckWrapper,
    AttributeSelection,
    Explainer,
    Button,
    ImageVue,
  },
  data() {
    return {
      isAdd: true,
      chosenID: 0,
      wpAddress: 0,
      WPTokenAddress: 0,
      ownedWP: 0,
      Avax: 0,
      gAvax: 0,
      AvaxBal: 0,
      gAvaxBal: 0,
      maxSingularWithdrawal: [0, 0],
      combinedWithdrawal: [0, 0],
      slippage: 0,
      deadline: 0,
      approval: false,
      WPApproval: false,
      percentage: 0,
      forecastedWPamount: 0,
      loading: true,
      loadingData: true,
      poolData: {
        aParameter: 60,
        virtualPrice: "---",
        fee: "0.04",
        adminFee: "0.02",
        reserve1: "---",
        reserve2: "---",
        peg: 1,
      },
    };
  },
  mounted: async function () {
    this.chosenID = this.$route.params.id;
    await this.updateData();
    await this.catchPoolData();
  },
  computed: {
    ...mapGetters({
      userAddress: "userAddress",
      web3: "web3",
      contracts: "contracts",
      planetIds: "planetIds",
    }),
    ButtonText: function () {
      if (!this.userAddress) {
        return "Connect";
      } else if (
        (this.Avax <= 0 || this.Avax == "") &&
        (this.gAvax <= 0 || this.gAvax == "")
      ) {
        return "Input";
      } else if (this.isAdd) {
        if (this.Avax > this.AvaxBal) return "Insufficient Avax";
        else if (this.gAvax > this.gAvaxBal) return "Insufficient gAvax";
        else if (!this.approval) return "Approve gAvax";
        return "Deposit";
      } else {
        if (BN(this.Avax).gt(BN(this.maxSingularWithdrawal[0])))
          return "Avax amount exceeds";
        else if (BN(this.GAvax).gt(BN(this.maxSingularWithdrawal[1])))
          return "gAvax amount exceeds";
        else if (
          this.forecastedWPamount
            ? this.forecastedWPamount.gt(BN(this.ownedWP).toString())
            : this.percentage > 0
        )
          return "Insufficient WP Token";
        else if (this.WPApproval.lt(this.ownedWP)) return "Approve WP Token";
        return "Withdraw";
      }
    },
    isDisabled: function () {
      return (
        this.loading ||
        !(
          this.ButtonText == "Deposit" ||
          this.ButtonText == "Withdraw" ||
          this.ButtonText == "Approve gAvax" ||
          this.ButtonText == "Approve WP Token" ||
          this.ButtonText == "Connect"
        )
      );
    },
    displayBalances: function () {
      return [
        this.AvaxBal > 0 ? BN(this.AvaxBal).toFixed(2).toString() : 0,
        this.gAvaxBal > 0 ? BN(this.gAvaxBal).toFixed(2).toString() : 0,
        this.ownedWP > 0
          ? BN(this.ownedWP).dividedBy(1e18).toFixed(2).toString()
          : 0,
      ];
    },
  },
  watch: {
    async userAddress() {
      if (this.contracts) {
        await this.update();
        await this.fillPoolData();
      }
    },
    async contracts() {
      if (this.userAddress) {
        await this.update();
        await this.fillPoolData();
      }
    },
  },
  methods: {
    ...mapActions(["setAddress"]),
    async addMetamask() {
      if (!this.userAddress) {
        await this.setAddress();
        return;
      }
      const self = this;
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: self.WPTokenAddress, // The address that the token is at.
            symbol: "YieldYak-WP", // A ticker symbol or shorthand, up to 5 chars.
            decimals: 18, // The number of decimals in the token
            image: "https://i.ibb.co/g9gLWt0/geode-yy.png", // A string url of the token logo
          },
        },
      });
    },
    async fillPoolData() {
      this.loadingData = true;
      var poolData = {
        aParameter: 0,
        virtualPrice: 0,
        fee: 0,
        adminFee: 0,
        reserve1: 0,
        reserve2: 0,
      };
      try {
        const wpool = getWithdrawalPool(this.wpAddress);
        const swapStorage = await wpool.methods.swapStorage().call();
        poolData.aParameter = await wpool.methods.getA().call();
        poolData.virtualPrice = BN(await wpool.methods.getVirtualPrice().call())
          .dividedBy(1e18)
          .toFixed(3)
          .toString();
        poolData.fee = BN(swapStorage.swapFee)
          .dividedBy(1e10)
          .times(1e2)
          .toFixed(2)
          .toString();
        poolData.adminFee = BN(swapStorage.adminFee)
          .times(poolData.fee)
          .times(1e2)
          .dividedBy(1e20)
          .toFixed(2)
          .toFixed(1)
          .toString();
        poolData.reserve2 = BN(await wpool.methods.getTokenBalance(1).call())
          .dividedBy(1e18)
          .toFixed(1)
          .toString();
        poolData.reserve1 = BN(await wpool.methods.getTokenBalance(0).call())
          .dividedBy(1e18)
          .toFixed(1)
          .toString();
      } catch {
        this.loadingData = false;
      }
      this.poolData = poolData;
      this.loadingData = false;
    },
    async catchPoolData() {
      this.loadingData = true;
      var poolData = {
        aParameter: 0,
        virtualPrice: 0,
        fee: 0,
        adminFee: 0,
        reserve1: 0,
        reserve2: 0,
      };
      try {
        var data = (
          await axios.get(`https://api-avax-1efd0eddc708.herokuapp.com/api/avax/pool/${this.chosenID}`)
        ).data.data;
        poolData.aParameter = BN(data.aParameter).toFixed(1).toString();
        poolData.peg = BN(data.peg).toFixed(1).toString();
        poolData.virtualPrice = BN(data.virtualPrice)
          .dividedBy(1e18)
          .toFixed(3)
          .toString();
        poolData.fee = BN(data.fee)
          .dividedBy(1e10)
          .times(1e2)
          .toFixed(2)
          .toString();
        poolData.adminFee = BN(data.adminFee)
          .times(data.fee)
          .times(1e2)
          .dividedBy(1e20)
          .toFixed(2)
          .toString();
        poolData.reserve1 = BN(data.reserve1)
          .dividedBy(1e18)
          .toFixed(1)
          .toString();
        poolData.reserve2 = BN(data.reserve2)
          .dividedBy(1e18)
          .toFixed(1)
          .toString();
      } catch {
        this.loadingData = false;
      }
      this.poolData = poolData;
      this.loadingData = false;
    },
    async Switch(value) {
      this.isAdd = value;
      await this.update();
    },
    setSlippage(value) {
      this.slippage = value;
    },
    setDeadline(value) {
      this.deadline = value;
    },
    async setValue(name, value) {
      if (name == "Avax") {
        this.Avax = value;
      } else {
        this.gAvax = value;
      }
      this.forecastedWPamount = await this.forecastWPamount();
    },
    setByPercentage(e) {
      if (this.web3) {
        this.$refs.InputSpace1.Update(
          BN(this.combinedWithdrawal[0])
            .times(e.target.value)
            .dividedBy(1e20)
            .toString()
        );
        this.$refs.InputSpace2.Update(
          BN(this.combinedWithdrawal[1])
            .times(e.target.value)
            .dividedBy(1e20)
            .toString()
        );
      }
    },
    clearPercentage() {
      this.percentage = 0;
    },
    async maxedOne(name) {
      if (!this.isAdd) {
        this.percentage = 0;
        if (name == "Avax") {
          this.gAvax = 0;
          this.$refs.InputSpace2.Update(0);
        } else {
          this.Avax = 0;
          this.$refs.InputSpace1.Update(0);
        }
      }
    },
    async forecastWPamount() {
      const BNAvax = this.Avax
        ? BN(BN(this.Avax).times(1e18).toFixed(0).toString()).toString()
        : BN("0").toString();
      const BNgAvax = this.gAvax
        ? BN(BN(this.gAvax).times(1e18).toFixed(0).toString()).toString()
        : BN("0").toString();
      var amounts = [BNAvax, BNgAvax];
      const y = await getWithdrawalPool(this.wpAddress)
        .methods.calculateTokenAmount(amounts, this.isAdd)
        .call();
      const x = BN(y.toString());
      return x;
    },
    async update() {
      this.loading = true;
      try {
        await this.clearValues();
        await this.updateData();
      } catch {
        this.loading = false;
      }
    },
    async clearValues() {
      this.Avax = BN(0).toString();
      this.gAvax = BN(0).toString();
      this.AvaxBal = BN(0).toString();
      this.gAvaxBal = BN(0).toString();
      this.ownedWP = BN(0).toString();
      this.percentage = 0;
      this.approval = false;
      this.WPApproval = BN(0).toString();
      this.maxSingularWithdrawal = [0, 0];
      this.combinedWithdrawal = [0, 0];
      this.$refs.InputSpace1.Clear();
      this.$refs.InputSpace2.Clear();
    },
    async updateData() {
      this.loading = true;
      await timeout(2000);
      if (this.web3) {
        this.wpAddress = await this.contracts.Portal.methods
          .planetWithdrawalPool(this.chosenID)
          .call();

        this.WPTokenAddress = await this.contracts.Portal.methods
          .planetLPToken(this.chosenID)
          .call();

        this.approval = await this.contracts.gAVAX.methods
          .isApprovedForAll(this.userAddress, this.wpAddress)
          .call();

        this.WPApproval = BN(
          await getWPToken(this.WPTokenAddress)
            .methods.allowance(this.userAddress, this.wpAddress)
            .call()
        );

        this.AvaxBal = BN(await this.web3.eth.getBalance(this.userAddress))
          .div(1e18)
          .toString();

        this.gAvaxBal = BN(
          await this.contracts.gAVAX.methods
            .balanceOf(this.userAddress, this.chosenID)
            .call()
        )
          .div(1e18)
          .toString();

        this.ownedWP = BN(
          await getWPToken(this.WPTokenAddress)
            .methods.balanceOf(this.userAddress)
            .call()
        );
        const ownedWP = this.ownedWP.minus(1).toFixed(0).toString();
        const zeroMax = BN(
          await getWithdrawalPool(this.wpAddress)
            .methods.calculateRemoveLiquidityOneToken(
              ownedWP > 0 ? ownedWP : 0,
              0
            )
            .call()
        );
        const oneMax = BN(
          await getWithdrawalPool(this.wpAddress)
            .methods.calculateRemoveLiquidityOneToken(
              ownedWP > 0 ? ownedWP : 0,
              1
            )
            .call()
        );
        const tempMax = [
          zeroMax.div(1e18).toString(),
          oneMax.div(1e18).toString(),
        ];
        this.maxSingularWithdrawal = tempMax;

        const tempComb = await getWithdrawalPool(this.wpAddress)
          .methods.calculateRemoveLiquidity(this.ownedWP.minus(1).toString())
          .call();
        this.combinedWithdrawal = tempComb;
      }
      this.loading = false;
    },
    getWithdrawalType(amounts) {
      if (this.percentage > 0) return 4;
      // removeLiquidity
      else if (BN(amounts[0]).eq(BN(this.maxSingularWithdrawal[0]).times(1e18)))
        return 1;
      // removeLiquidityOneToken(0)
      else if (BN(amounts[1]).eq(BN(this.maxSingularWithdrawal[1]).times(1e18)))
        return 2;
      // removeLiquidityOneToken(1)
      else if (BN(amounts[1]).gt(0) || BN(amounts[0]).gt(0)) return 3;
      // removeLiquidityImbalance
      else return 0;
      // undefined
    },
    async Approve() {
      const self = this;
      await this.contracts.gAVAX.methods
        .setApprovalForAll(this.wpAddress, true)
        .send({
          from: this.userAddress,
        })
        .on("transactionHash", function (hash) {
          notifyHandler(hash);
        })
        .once("confirmation", async function () {
          await self.updateData();
        })
        .on("error", (err) => {
          console.log(err);
        })
        .catch((err) => {
          console.log(err);
        });
      await self.updateData();
    },
    async ApproveWP() {
      const self = this;
      const token = getWPToken(this.WPTokenAddress);
      await token.methods
        .approve(this.wpAddress, maxUint)
        .send({
          from: this.userAddress,
        })
        .on("transactionHash", function (hash) {
          notifyHandler(hash);
        })
        .once("confirmation", async function () {
          await self.updateData();
        })
        .on("error", (err) => {
          console.log(err);
        })
        .catch((err) => {
          console.log(err);
        });
      await self.updateData();
    },
    async Execute() {
      this.loading = true;
      if (this.ButtonText == "Connect") {
        await this.setAddress();
        this.loading = false;
        return;
      }
      if (this.isAdd & (this.ButtonText == "Approve gAvax")) {
        await this.Approve();
        this.loading = false;
        return;
      }
      if (!this.isAdd & (this.ButtonText == "Approve WP Token")) {
        await this.ApproveWP();
        this.loading = false;
        return;
      }
      const self = this;
      const wpool = getWithdrawalPool(this.wpAddress);
      const deadline = Math.floor(Date.now() / 1000 + this.deadline * 60);
      const BNAvax = this.Avax
        ? BN(BN(this.Avax).times(1e18).toFixed(0)).toString()
        : BN(0).toString();
      const BNgAvax = this.gAvax
        ? BN(BN(this.gAvax).times(1e18).toFixed(0)).toString()
        : BN(0).toString();
      const amounts = [BNAvax, BNgAvax];
      if (this.ButtonText == "Deposit") {
        var expected = BN(
          await wpool.methods.calculateTokenAmount(amounts, true).call()
        );
        var min = expected
          .times(100 - this.slippage)
          .dividedBy(100)
          .toFixed(0)
          .toString();
        await wpool.methods
          .addLiquidity(amounts, min, deadline)
          .send({
            from: this.userAddress,
            value: BNAvax,
          })
          .on("transactionHash", function (hash) {
            notifyHandler(hash);
          })
          .once("confirmation", () => {
            self.$emit("clicked");
          })
          .on("error", (err) => {
            this.loading = false;
            console.log(err);
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
        this.loading = false;
      }
      if (this.ButtonText == "Withdraw") {
        const withdrawalType = this.getWithdrawalType(amounts);
        if (withdrawalType == 4) {
          // given percentage, withdraw with the pool ratio : removeLiquidity
          var burnedWP = this.ownedWP
            .minus(1)
            .times(this.percentage)
            .dividedBy(100)
            .toFixed(0);
          var minAmounts = amounts.map((e) =>
            BN(e)
              .times(100 - this.slippage)
              .dividedBy(100)
              .toFixed(0)
              .toString()
          );
          await wpool.methods
            .removeLiquidity(burnedWP, minAmounts, deadline)
            .send({
              from: this.userAddress,
            })
            .on("transactionHash", function (hash) {
              notifyHandler(hash);
            })
            .once("confirmation", async function () {
              await self.updateData();
            })
            .on("error", (err) => {
              console.log(err);
            })
            .catch((err) => {
              console.log(err);
            });
          this.loading = false;
        } else if (withdrawalType == 3) {
          // inputted, withdraw Imbalance : removeLiquidityImbalance
          const burnWP = BN(
            BN(await wpool.methods.calculateTokenAmount(amounts, false).call())
              .plus(1)
              .times(100 + this.slippage * 1.01)
              .dividedBy(100)
              .toFixed(0)
          );
          const burnWPmax = burnWP.gt(BN(this.ownedWP))
            ? BN(this.ownedWP).minus(1).toString()
            : burnWP.toString();
          await wpool.methods
            .removeLiquidityImbalance(amounts, burnWPmax, deadline)
            .send({
              from: this.userAddress,
            })
            .on("transactionHash", function (hash) {
              notifyHandler(hash);
            })
            .once("confirmation", async function () {
              await self.updateData();
            })
            .on("error", (err) => {
              console.log(err);
            })
            .catch((err) => {
              console.log(err);
            });
          this.loading = false;
        } else if (withdrawalType == 1 || withdrawalType == 2) {
          // MAXED one token here, : removeLiquidityOneToken(index)
          const index = withdrawalType - 1;
          const minAmount = BN(amounts[index])
            .times(100 - this.slippage)
            .dividedBy(100)
            .toFixed(0)
            .toString(); //SLIPPAGE CHANGE THIS
          var WPamount = this.ownedWP.toString();
          await wpool.methods
            .removeLiquidityOneToken(WPamount, index, minAmount, deadline)
            .send({
              from: this.userAddress,
            })
            .on("transactionHash", function (hash) {
              notifyHandler(hash);
            })
            .once("confirmation", async function () {
              await self.updateData();
            })
            .on("error", (err) => {
              console.log(err);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
      await this.updateData();
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.couple-button {
  max-width: 1144px;
}
.DWP {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1800px;
  width: 95%;
}
p {
  margin: 2px;
}
.PoolView {
  color: #fff;
  padding: 0;
  margin-bottom: 25px;
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.split {
  border-radius: 6px;
}
.shared-bg {
  background-image: url("~@/assets/bg.png");
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* <- here it is */
}
.poolDetails,
.poolAction {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  max-width: 1000px;
  border-radius: 16px;
  padding: 40px 72px;
  margin-top: 25px;
}
.poolDetails > .align-vertically {
  justify-content: center;
}
.left-button {
  border-radius: 6px 0 0 6px;
}
.right-button {
  border-radius: 0 6px 6px 0;
}
.switch-off-button,
.switch-on-button {
  width: 100%;
  min-height: 55px;
  transition: 0.3s ease-in-out;
  text-align: center;
}
.switch-on-button {
  font-size: 24px;
  background: #18aaa180;
  padding: 12px 0px;
}
.switch-off-button {
  font-size: 16px;
  padding: 12px 0px;
  background-color: transparent;
  color: rgb(255, 255, 255, 0.6);
}
.split-six {
  display: grid;
  gap: 2px;
  text-align: center;
  grid-template-areas: "area1 area2 area3 area4 area5 area6";
}

.circle {
  margin: auto;
  margin-top: 1ch;
  height: 25px;
  width: 25px;
  border-radius: 50%;
}
.approval {
  padding: 20px 0 20px 0;
}

.split-six > div {
  padding: 0px 5px;
  border-style: none solid none none;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.4);
}
.split-six > .non-border {
  border: none;
  padding-left: 10px;
}
.red-bg {
  background-color: rgb(255, 61, 122, 0.75);
}
.green-bg {
  background-color: rgb(1, 234, 214, 0.75);
}
.stat-name {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 100%;
}
.stat-data {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 42px;
  letter-spacing: -0.02em;
}
.input-avax {
  margin: 0 15px 0 0;
}
.input-gavax {
  margin: 0 0 0 15px;
}
.connect-button {
  background-color: #104946;
  color: #9efff9;
  border-radius: 10px;
  padding: 15px 40px;
  align-self: flex-end;
  margin-top: 15px;
}
.button-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.add-button {
  margin: auto;
}
.button-zone {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.input-wrapper {
  padding: 2ch 0;
}
input[type="range"] {
  display: block;
  -webkit-appearance: none;
  background-color: #fff;
  margin-top: 16px;
  min-width: 300px;
  height: 3px;
  border-radius: 5px;
  outline: 0;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #18aaa1;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid transparent;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
input[type="range"]::-webkit-slider-thumb:hover {
  background-color: transparent;
  border: 2px solid #18aaa1;
}
input[type="range"]::-webkit-slider-thumb:active {
  transform: scale(1.05);
}

@media screen and (max-width: 1800px) {
}
@media screen and (max-width: 1350px) {
}
@media screen and (max-width: 820px) {
  .input-gavax {
    margin: 0 15px 0 0;
  }
  .poolAction {
    padding: 40px 24px;
  }
  .poolDetails {
    padding: 32px 24px;
  }
}
@media screen and (max-width: 720px) {
  .stat-wrapper {
    height: 100%;
  }
  .split-six {
    display: grid;
    gap: 20px 2px;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    text-align: center;
    grid-template-areas:
      "area1 area2 area3 area5"
      "area1 area2 area4 area6";
    align-items: start;
  }
}
@media screen and (max-width: 620px) {
  .approval,
  .percentage {
    font-size: 15px;
  }
  .split-six > .non-border-inmobile {
    border: none;
  }
  .split-six {
    gap: 0;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1.2fr 1fr 1fr;
    grid-template-areas:
      "area1 area3 area5"
      "area1 area3  area5"
      "area2 area4 area6";
  }
  .stat-name {
    font-size: 15px;
  }
  .stat-data {
    font-size: 18px;
  }
  .switch-on-button {
    font-weight: 700;
    font-size: 16px;
  }
  .switch-off-button {
    font-size: 14px;
  }
}
@media screen and (max-width: 500px) {
  .split-six > .non-border-in2column {
    border: none;
  }
  .split-six > .non-border-inmobile {
    border-style: none solid none none;
    border-width: 1px;
    border-color: rgba(255, 255, 255, 0.4);
  }
  .split-six > .top-border-inmobile {
    padding-top: 5px;
    border-top: solid;
    border-width: 1px;
    border-color: rgba(255, 255, 255, 0.4);
  }
  .split-six {
    gap: 0;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 0.6fr 1fr;
    grid-template-areas:
      "area1 area1  area2 "
      "area1 area1 area2 "
      "area3 area3 area5 "
      "area4 area4 area6 ";
  }
}
</style>
